import React, { useContext, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as XLSX from 'xlsx';
import { Button } from '@mantine/core';
import { AnalyticsContext } from './AnalyticsContext';

const PivotTable = () => {
    const gridRef = useRef();
    const { pivotData, groupBy, asOfDate, columnDisplayNames } = useContext(AnalyticsContext);

    // Build columnDefs dynamically based on groupBy
    // GroupBy columns:
    const groupColumns = groupBy.map(column => ({
        headerName: columnDisplayNames[column] || column,
        field: column
    }));

    const columnDefs = [
        ...groupColumns,
        {
            headerName: 'Összes munkaóra',
            field: 'totalMunkaora',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00';
            }
        },
        {
            headerName: 'Kész munkaórák',
            field: 'totalKeszMunkaora',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00';
            }
        },
        {
            headerName: 'Elkészültég (%)',
            field: 'keszPercentage',
            valueFormatter: (params) => {
                const value = params.value;
                return typeof value === 'number' ? value.toLocaleString('hu-HU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0.00%';
            }
        }
    ];

    const handleExport = () => {
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));

        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Pivot Data');

        const formattedDate = asOfDate.format('YYYYMMDD');
        const fileName = `pivot_data_${groupBy.join('_')}_${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, fileName);
    };

    return (
        <div className="pivot-table-container" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className="ag-theme-alpine" style={{ width: '100%', flexGrow: 1 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={pivotData}
                    columnDefs={columnDefs}
                    defaultColDef={{ flex: 1, sortable: true, filter: true, resizable: true }}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '100%', margin: '5px', padding: '5px' }}>
                <Button size="xs" color="blue" variant="outline" onClick={handleExport}>Excel letöltés</Button>
            </div>
        </div>
    );
};

export default PivotTable;
