import { useState, useEffect, useContext } from 'react';
import { Button, TextInput, Select } from '@mantine/core';
import { GlobalContext } from '../../Home/GlobalContext';
import uniqueMaterials from '../../../util/GetUniqueValues';

const AddSheetForm = ({
  sheets,
  setSheets,
  selectedRev,
  setShowSheetModal,
}) => {
  // Get project settings and subcontractors from global context
  const { projectSettings, subcontractors, projectIDGlobal } = useContext(GlobalContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  // State for materials
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    fetch(`${apiUrl}/api/materials`)
      .then((response) => response.json())
      .then((data) => {
        const columns = projectSettings.anyagminoseg_cols || [];
        const concatData = uniqueMaterials(data, columns);
        setMaterials(concatData.list);
      });
  }, [apiUrl, projectSettings.anyagminoseg_cols]);

  // State for tags by category
  const [tagsByCategory, setTagsByCategory] = useState({}); // { category1: [{id: 1, name: 'tag1'}, ...], ... }
  useEffect(() => {
    fetch(`${apiUrl}/api/tags?project_id=${projectIDGlobal}`)
      .then((response) => response.json())
      .then((data) => {
        setTagsByCategory(data);
      });
  }, [apiUrl, projectIDGlobal]);

  // Form state
  const [newSheetNumber, setNewSheetNumber] = useState('');
  const [newHeight, setNewHeight] = useState('');
  const [newMaterial, setNewMaterial] = useState('');
  const [newSubcontractor, setNewSubcontractor] = useState('');
  const [selectedTags, setSelectedTags] = useState({}); // { category1: {id: null, name: 'newTag'}, ... }

  const handleAddSheet = () => {
    // Validate inputs
    if (!newSheetNumber || isNaN(newSheetNumber)) {
      alert('Érvénytelen lap szám'); // Invalid sheet number
      return;
    }
    if (!newHeight || isNaN(newHeight)) {
      alert('Érvénytelen magasság'); // Invalid height
      return;
    }
    if (!newMaterial) {
      alert('Anyag hiányzik'); // Missing material
      return;
    }
    if (!newSubcontractor) {
      alert('Alvállalkozó hiányzik'); // Missing subcontractor
      return;
    }
  
    const requestBody = {
      rev_id: selectedRev.id,
      sheet_number: parseInt(newSheetNumber, 10),
      height: parseInt(newHeight, 10),
      material: newMaterial,
      subcontractor: newSubcontractor,
      tags: selectedTags,
    };
  
    fetch(`${apiUrl}/api/sheets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          // Handle HTTP errors
          return response.json().then((data) => {
            throw new Error(data.error || 'Failed to add sheet');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.sheet_id) {
          setSheets((prevSheets) => [...prevSheets, {id: data.sheet_id, name: data.sheet_number.toString()}]);
          setShowSheetModal(false);
          // Optionally reset fields here
        } else {
          alert('Sikertelen hozzáadás'); // Failed to add
        }
      })
      .catch((error) => {
        console.error('Error adding sheet:', error);
        alert(`Hiba történt: ${error.message}`); // An error occurred
      });
  };

  return (
    <div className="AddSheetForm">
      <TextInput
        label="Lap száma:"
        value={newSheetNumber}
        onChange={(event) => setNewSheetNumber(event.currentTarget.value)}
      />
      <TextInput
        label="Lap magassága:"
        value={newHeight}
        onChange={(event) => setNewHeight(event.currentTarget.value)}
      />
      <Select
        label="Lap anyaga:"
        data={materials}
        value={newMaterial}
        onChange={(value) => setNewMaterial(value)}
        searchable={true}
        dropdownPosition="bottom"
        withinPortal
      />
      <Select
        label="Lap alvállakozó:"
        data={subcontractors.map((subcontractor) => subcontractor.name)}
        value={newSubcontractor}
        onChange={(value) => setNewSubcontractor(value)}
        searchable={true}
        dropdownPosition="bottom"
        withinPortal
      />
      {Object.keys(tagsByCategory).map((category) => (
        <Select
          key={category}
          label={category}
          data={tagsByCategory[category].map((tag) => ({
            value: tag.name,
            label: tag.name,
          }))}
          value={selectedTags[category]?.name || ''}
          onChange={(value) => {
            const selectedTag = tagsByCategory[category].find(
              (tag) => tag.name === value
            );
            if (selectedTag) {
              setSelectedTags({ ...selectedTags, [category]: selectedTag });
            }
          }}
          searchable
          dropdownPosition="bottom"
          creatable
          withinPortal
          getCreateLabel={(query) => `+ Create "${query}"`}
          onCreate={(query) => {
            const newTag = { id: null, name: query };
            setTagsByCategory({
              ...tagsByCategory,
              [category]: [...tagsByCategory[category], newTag],
            });
            setSelectedTags({ ...selectedTags, [category]: newTag });
            return query;
          }}
        />
      ))}
      <Button className="mt-1" onClick={handleAddSheet}>
        Mentés
      </Button>
    </div>
  );
};

export default AddSheetForm;
