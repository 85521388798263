import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { Button, Badge, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../../Atoms/Loading';
import { Loader } from '@mantine/core';
import * as moment from 'moment';
import AuthContext from '../../../Home/AuthContext';
import { GlobalContext } from '../../../Home/GlobalContext';
import AgGridSingleSelectEditor from '../../../../util/AgGridSingleSelectEditor';

const CsotartoTrackingTable = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [origData, setOrigData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [workers, setWorkers] = useState([]);
    const [canSave, setCanSave] = useState(true);

    const { user } = useContext(AuthContext);
    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const gridRef = useRef();

    // Fetch Csotarto tracking data when selectedSheet.id changes
    useEffect(() => {
        setLoading(true);

        fetch(`${apiUrl}/api/get_csotarto_tracking?sheet_id=${selectedSheet.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const parsedData = data;

                // Sort the data based on 'sorszam'
                parsedData.sort((a, b) => a.sorszam - b.sorszam);

                // Process each row to extract the latest progress_entry
                parsedData.forEach((row) => {
                    if (row.progress_entries && row.progress_entries.length > 0) {
                        // Find the latest progress_entry based on 'date'
                        const latestProgressEntry = row.progress_entries.reduce((latest, entry) => {
                            return new Date(entry.recorded_date) > new Date(latest.recorded_date) ? entry : latest;
                        }, row.progress_entries[0]);

                        row.date = latestProgressEntry.date ? moment(latestProgressEntry.date).toDate() : null;
                        row.is_done = latestProgressEntry.is_done;
                        row.progress_id = latestProgressEntry.id;
                        row.orig_progress_id = latestProgressEntry.id;
                    } else {
                        row.date = null;
                        row.is_done = false;
                        row.progress_id = null;
                        row.orig_progress_id = null;
                    }

                    row.modified = false; // Track if the row has been modified
                });

                console.log('parsedData: ', parsedData);
                setRowData(parsedData);
                setOrigData(JSON.parse(JSON.stringify(parsedData)));
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching csotarto tracking:', error);
                setLoading(false);
            });
    }, [apiUrl, selectedSheet.id]);

    // Fetch workers data
    useEffect(() => {
        // Fetch workers
        fetch(`${apiUrl}/api/workforce?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(data => data.filtered_workers || data.workers || [])
            .then(data => {
                const formattedWorkers = data.map(worker => ({
                    value: worker.id,
                    label: `${worker.name} | ${worker.tag}`
                }));
                setWorkers(formattedWorkers);
            })
            .catch(error => {
                console.error('Error fetching workers:', error);
            });
    }, [apiUrl, projectIDGlobal, user.userId]);

    // Update canSave state based on rowData and project settings
    useEffect(() => {
        if (projectSettings && projectSettings.require_csotarto_completed_by) {
            const invalidRows = rowData.filter(row => row.is_done && (!row.done_by || row.done_by === ''));
            setCanSave(invalidRows.length === 0);
        } else {
            setCanSave(true);
        }
    }, [rowData, projectSettings]);

    // Define column definitions
    const columnDefs = useMemo(() => [
        {
            headerName: 'Mentett',
            field: 'modified',
            cellRenderer: (params) => (
                params.value === true ? <Badge color='red'>Nem</Badge> : <Badge color='green'>Igen</Badge>
            ),
            width: 120,
        },
        {
            headerName: "Elkészült",
            field: "is_done",
            editable: true,
            width: 150
        },
        { headerName: "Sorszám", field: "sorszam", width: 120 },
        { headerName: "Típus", field: "tipus", width: 200 },
        { headerName: "DN", field: "dn", width: 100 },
        { headerName: "Súly", field: "suly", width: 100 },
        { headerName: "Munkaóra", field: "munkaora", width: 120 },
        {
            headerName: "Elkészülés dátuma",
            field: "date",
            editable: (params) => params.data.is_done,
            cellRenderer: (params) => (params.value ? moment(params.value).format('YYYY-MM-DD') : ''),
            cellEditor: 'agDateCellEditor',
            cellDataType: 'date',
            width: 180,
        },
        {
            headerName: 'Készítette',
            field: 'done_by',
            editable: (params) => params.data.is_done,
            cellEditor: AgGridSingleSelectEditor, // Use the custom single select editor
            cellEditorParams: (params) => ({
                options: workers,
            }),
            valueFormatter: (params) => {
                // Format the value to show the label of the selected worker
                return workers.find(worker => worker.value === params.value)?.label || '';
            },
            filterValueGetter: (params) => {
                // Return the formatted label for filtering purposes
                return workers.find(worker => worker.value === params.value)?.label || '';
            },
            filter: 'agTextColumnFilter', // Use a text filter for matching the formatted label
            valueGetter: (params) => {
                // Get the value of 'done_by'
                return params.data.done_by;
            },
            valueSetter: (params) => {
                // Validate and set the value if it's a valid worker
                const selectedValue = params.newValue;
                if (workers.find(worker => worker.value === selectedValue)) {
                    params.data.done_by = selectedValue;
                    return true;
                }
                return false;
            },
            cellStyle: { overflow: 'visible', zIndex: 'auto' },
            flex: 1,
            suppressKeyboardEvent: (params) => {
                if (!params.editing) {
                    return false;
                }
                return true;
            },
            width: 200,
        },
        { headerName: 'Progress ID', field: 'progress_id', hide: true },
    ], [workers]);

    // Save data function
    const saveData = () => {
        const gridApi = gridRef.current.api;
        const updatedRowData = [];
        gridApi.forEachNode(node => updatedRowData.push(node.data));

        // Validation before saving
        if (projectSettings && projectSettings.require_csotarto_completed_by) {
            // Check if any completed items lack 'done_by'
            const invalidRows = updatedRowData.filter(row => row.is_done && (!row.done_by || row.done_by === ''));
            if (invalidRows.length > 0) {
                // Prevent saving and inform the user
                alert('Nem lehet menteni, mert vannak elkészült elemek, amelyeknél nincs megadva a "Készítette" mező.');
                return;
            }
        }

        setLoading(true);

        // Prepare the request body by mapping only necessary fields
        const requestBody = {
            data: updatedRowData.filter(row => row.modified).map(row => ({
                id: row.id, // csotarto_id
                sorszam: row.sorszam,
                sheet_id: row.sheet_id,
                tipus: row.tipus,
                dn: row.dn,
                suly: row.suly,
                munkaora: row.munkaora,
                date: row.is_done ? moment(row.date).toISOString() : null,
                done_by: row.is_done ? row.done_by : null,
                is_done: row.is_done,
            })),
            user_id: user.userId
        };

        console.log("requestBody: ", requestBody);

        fetch(`${apiUrl}/api/save_csotarto_tracking`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                // After successful save, reset 'modified' flags and update origData
                const resetRowData = updatedRowData.map(row => ({
                    ...row,
                    modified: false,
                    orig_progress_id: row.is_done ? row.progress_id : null
                }));
                setRowData(resetRowData);
                setOrigData(JSON.parse(JSON.stringify(resetRowData)));
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error.message);
                setLoading(false);
            });
    }

    // Handle cell value changes
    const onCellValueChanged = (params) => {
        console.log('params:', params);

        const id = params.data.id;
        console.log('id:', id);
        const originalRow = origData.find((row) => row.id === id);
        console.log('originalRow:', originalRow);
        console.log('params.data:', params.data);

        // Determine if the row has been modified by comparing to original data
        const isModified = Object.keys(params.data).some((key) =>
            key !== 'modified' &&
            key !== 'orig_progress_id' &&
            key !== 'wps_name' && // Exclude non-editable or derived fields
            params.data[key] !== originalRow[key]
        );
        console.log('isModified:', isModified);

        if (isModified) {
            params.data.modified = true;
        } else {
            params.data.modified = false;
            // Restore done_by and date from original progress entry if not modified
            if (originalRow.progress_entries && originalRow.progress_entries.length > 0) {
                const latestProgress = originalRow.progress_entries.sort((a, b) => new Date(b.recorded_date) - new Date(a.recorded_date))[0];
                params.data.done_by = latestProgress.done_by;
                params.data.date = latestProgress.date ? moment(latestProgress.date).toDate() : null;
            } else {
                params.data.done_by = null;
                params.data.date = null;
            }
        }

        // Handle 'is_done' field changes
        if (params.colDef.field === 'is_done') {
            if (params.data.is_done) {
                params.data.date = moment().toDate();
                console.log('Date set to:', moment().toDate());
            } else {
                params.data.date = null;
                params.data.done_by = null;
                console.log('Date and done_by reset to null');
            }
        }

        // Update the rowData state
        const updatedRowData = [...rowData];
        const rowIndex = updatedRowData.findIndex(row => row.id === params.data.id);
        if (rowIndex !== -1) {
            updatedRowData[rowIndex] = { ...params.data };
            setRowData(updatedRowData);
        } else {
            console.error('Row not found in rowData');
        }
    }

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <Tooltip
                label="Nem lehet menteni, mert vannak elkészült elemek, amelyeknél nincs megadva a 'Készítette' mező."
                disabled={canSave}
                position="bottom"
                withArrow
            >
                <div style={{ display: 'inline-block' }}>
                    <Button
                        onClick={saveData}
                        style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
                        disabled={!canSave}
                    >
                        Mentés
                    </Button>
                </div>
            </Tooltip>

            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                            minWidth: 100,
                        }}
                        onCellValueChanged={onCellValueChanged}
                    />
                </LoadingWrapper>
            </div>
        </>
    );
};

export default CsotartoTrackingTable;
