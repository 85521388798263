import { createContext, useContext, useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    const userData = {
                        username: decodedToken.sub,
                        userId: decodedToken.userId
                    };
                    setIsAuthenticated(true);
                    setUser(userData);
                    setToken(storedToken);
                }
            } catch (error) {
                console.error("Error decoding token: ", error);
            }
        }
        setLoading(false);
    }, []);

    const login = (userData, jwtToken, refreshToken) => {
        setIsAuthenticated(true);
        setUser(userData);
        setToken(jwtToken);
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('refresh_token', refreshToken);
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        navigate('/login');  // Redirect to login page
    };

    // Function to detect if the app is running as a PWA
    const isRunningStandalone = () => {
        return (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
            || (window.navigator.standalone === true);
    };

    // Inactivity Timer Logic
    useEffect(() => {
        if (!isAuthenticated) {
            // User is not authenticated, no need to set the timer
            return;
        }

        const isPWA = isRunningStandalone();

        if (isPWA) {
            // Do not set up inactivity timer for PWA
            return;
        }

        let inactivityTimeout;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(() => {
                // Log out the user after 8 hours of inactivity
                logout();
            }, 8 * 60 * 60 * 1000);  // 8 hours in milliseconds
        };

        // List of events that represent user activity
        const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchstart', 'touchmove'];

        // Attach event listeners
        events.forEach((event) => {
            window.addEventListener(event, resetInactivityTimer);
        });

        // Start the timer when the component mounts
        resetInactivityTimer();

        // Cleanup function
        return () => {
            // Remove event listeners
            events.forEach((event) => {
                window.removeEventListener(event, resetInactivityTimer);
            });
            // Clear the timeout
            clearTimeout(inactivityTimeout);
        };
    }, [isAuthenticated]);

    // ... (Include token refresh logic if applicable)

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, token, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
