import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, TextInput, Loader, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import checkDisabled from '../../../util/checkDisabled';

const Methods = () => {
    const fieldDisplayNames = {
        name: 'Név',
        description: 'Leírás',
    };

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [editingName, setEditingName] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [saveTooltip, setSaveTooltip] = useState('');

    const { projectIDGlobal } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                // Data is already parsed JSON
                setRowData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching methods:', error);
                setLoading(false);
            });
    }, [projectIDGlobal]);

    const columnDefs = useMemo(() => [
        {
            headerName: 'Műveletek',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => editRow(params.data)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        {
            field: 'name',
            headerName: fieldDisplayNames['name'],
            editable: false,
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.3 }}>{fieldDisplayNames['name']}</span>;
                }
                return params.value;
            }
        },
        {
            field: 'description',
            headerName: fieldDisplayNames['description'],
            editable: false,
        },
    ], []);

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowDataToSave = [];
        gridApi.forEachNode(node => {
            const data = { ...node.data };
            data.project_id = projectIDGlobal; // Ensure project_id is included
            rowDataToSave.push(data);
        });

        setLoading(true);

        fetch(`${apiUrl}/api/methods`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rowDataToSave)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error saving methods:', error);
                setLoading(false);
            });
    };

    const newRow = () => {
        const newRowData = {
            id: null, // Use null to indicate a new record
            name: '',
            description: '',
            project_id: projectIDGlobal, // Include project_id for new records
        };
        setCurrentRowData(newRowData);
        setEditingName('');
        setIsModalOpen(true);
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
        const updatedRowData = [];
        gridApi.forEachNode(node => updatedRowData.push(node.data));
        setRowData(updatedRowData);
    };

    const editRow = (rowData) => {
        setCurrentRowData(rowData);
        setEditingName(rowData.name);
        setIsModalOpen(true);
    };

    const handleModalChange = (field, value) => {
        setCurrentRowData({ ...currentRowData, [field]: value });
    };

    const isNameUnique = (name, originalName) => {
        return !rowData.some(row => row.name !== originalName && row.name === name);
    };

    useEffect(() => {
        const requiredFields = ['name'];

        const { isDisabled, toolTip } = checkDisabled(currentRowData, requiredFields, fieldDisplayNames);
        setSaveDisabled(isDisabled || !isNameUnique(currentRowData.name, editingName));
        setSaveTooltip(isNameUnique(currentRowData.name, editingName) ? toolTip : 'A név már létezik');
    }, [currentRowData]);

    const saveModalData = () => {
        if (editingName === '') {
            // Adding a new row
            setRowData([...rowData, currentRowData]);
        } else {
            // Editing an existing row
            const updatedRowData = rowData.map(row => {
                if (row.name === editingName) {
                    return currentRowData;
                }
                return row;
            });
            setRowData(updatedRowData);
        }
        setIsModalOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <Button
                onClick={saveData}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
            >
                Mentés
            </Button>
            <Button
                onClick={newRow}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
            >
                Új sor hozzáadása
            </Button>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        editType='modal'
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                        }}
                    />
                </LoadingWrapper>
            </div>

            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                title={editingName ? "Szerkesztés" : "Új módszer hozzáadása"}
            >
                <TextInput
                    label={fieldDisplayNames['name']}
                    value={currentRowData.name || ''}
                    onChange={(event) => handleModalChange('name', event.currentTarget.value)}
                    required
                    error={!isNameUnique(currentRowData.name, editingName) && 'A név már létezik'}
                />
                <TextInput
                    label={fieldDisplayNames['description']}
                    value={currentRowData.description || ''}
                    onChange={(event) => handleModalChange('description', event.currentTarget.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closeModal}>
                        Mégse
                    </Button>
                    <Tooltip
                        label={saveTooltip}
                        disabled={!saveDisabled}
                        withinPortal
                        withArrow
                    >
                        <div>
                            <Button onClick={saveModalData} disabled={saveDisabled}>
                                Mentés
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </Modal>
        </>
    );
};

export default Methods;
