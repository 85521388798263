import React, { createContext, useState } from 'react';
import dayjs from 'dayjs';

export const AnalyticsContext = createContext();

const AnalyticsProvider = ({ children }) => {
    const [groupBy, setGroupBy] = useState([]);
    const [rawData, setRawData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [pivotData, setPivotData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [asOfDate, setAsOfDate] = useState(dayjs(new Date()));
    const [filters, setFilters] = useState({});
    const [columnDisplayNames, setColumnDisplayNames] = useState({});
    const [groupByOptions, setGroupByOptions] = useState([]);

    return (
        <AnalyticsContext.Provider value={{
            groupBy,
            rawData,
            filteredData,
            pivotData,
            loading,
            asOfDate,
            filters,
            chartData,
            columnDisplayNames,
            groupByOptions,
            setGroupBy,
            setRawData,
            setFilteredData,
            setPivotData,
            setLoading,
            setAsOfDate,
            setFilters,
            setChartData,
            setColumnDisplayNames,
            setGroupByOptions
        }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export default AnalyticsProvider;
