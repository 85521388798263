import React, { createContext, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

// Create the context
const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    const location = useLocation();
    const [projectNameGlobal, setProjectNameGlobal] = useState('');
    const [projectIDGlobal, setProjectIDGlobal] = useState('');
    const [permissionsGlobal, setPermissionsGlobal] = useState(undefined);
    const [subcontractors, setSubcontractors] = useState([]);
    const [visibleSubcontractors, setVisibleSubcontractors] = useState([]);
    const [projectSettings, setProjectSettings] = useState({anyagminoseg_cols: [], require_done_by: false});
    
    const { user } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const extractProjectIDFromURL = () => {
        const pathSegments = location.pathname.split('/');
        const projectIndex = pathSegments.indexOf('project');
        if (projectIndex !== -1 && projectIndex + 1 < pathSegments.length) {
            const projectID = pathSegments[projectIndex + 1];
            return /^\d+$/.test(projectID) ? projectID : '';
        }
        return '';
    };

    useEffect(() => {
        console.log('Settings:', projectSettings);
    }, [projectSettings]);

    useEffect(() => {
        const projectID = extractProjectIDFromURL();

        if (!projectID) {
            setProjectNameGlobal('');
        }

        // Fetch project name based on the project ID
        if (projectID && projectID !== projectIDGlobal) {
            setProjectIDGlobal(projectID);
            fetch(`${apiUrl}/api/project_info?project_id=${projectID}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => response.json())
            .then(data => {
                console.log('Project name:', data);
                setProjectNameGlobal(data.project_name);
                setProjectSettings({
                    anyagminoseg_cols: data.anyagminoseg_cols,
                    csotarto_require_completed_by: data.csotarto_require_completed_by,
                    hegesztes_require_completed_by: data.hegesztes_require_completed_by,
                    csovezetek_require_completed_by: data.csovezetek_require_completed_by,
                    karimaszereles_require_completed_by: data.karimaszereles_require_completed_by,
                    nyomasproba_require_completed_by: data.nyomasproba_require_completed_by
            })
            })
            .catch(error => {
                console.error('Error fetching project name:', error);
            });
        }
    }, [apiUrl, location]);

    useEffect(() => {
        // Fetch permissions
        if (projectIDGlobal) {
            if (user && user.userId && projectIDGlobal) {
                fetch(`${apiUrl}/api/permissions?project_id=${projectIDGlobal}&user_id=${user.userId}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                })
                .then(response => response.json())
                .then(data => {
                    setPermissionsGlobal(data);
                })
                .catch(error => {
                    console.error('Error fetching permissions:', error);
                });
            }
        }
    }, [apiUrl, user, projectIDGlobal]);

    useEffect(() => {
        if (!projectIDGlobal) {
            return;
        }
        if (!user || !user.userId) {
            return;
        }
        fetch(`${apiUrl}/api/subcontractors?project_id=${projectIDGlobal}&user_id=${user.userId}`)
          .then(response => response.json())
          .then(data => {
            console.log('Subcontractors:', data);
            setSubcontractors(data.all_subcontractors);
            setVisibleSubcontractors(data.valid_subcontractors);
            });
    }, [apiUrl, projectIDGlobal]);

    
    return (
        <GlobalContext.Provider value={{
            projectNameGlobal,
            setProjectNameGlobal,
            projectIDGlobal,
            setProjectIDGlobal,
            permissionsGlobal,
            setPermissionsGlobal,
            subcontractors,
            setSubcontractors,
            projectSettings,
            setProjectSettings,
            visibleSubcontractors,
            setVisibleSubcontractors
        }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
