import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import { Loader } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import SettingsRibbon from './SettingsRibbon';
import PivotTable from './PivotTable';
import ChartView from './ChartView';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Button } from '@mui/material';
import { AnalyticsContext } from './AnalyticsContext';

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);

const Analytics = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { projectIDGlobal, permissionsGlobal } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const [viewMode, setViewMode] = useState('table');

    const {
        groupBy,
        rawData,
        filteredData,
        pivotData,
        loading,
        asOfDate,
        filters,
        setRawData,
        setFilteredData,
        setPivotData,
        setLoading,
        setColumnDisplayNames,
        setGroupByOptions
    } = useContext(AnalyticsContext);

    useEffect(() => {
        if (!user) return;
        if (!user.userId) return;
        setLoading(true);
        fetch(`${apiUrl}/api/pivot_data?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(data => {
                // Transform the data: we will flatten tags into separate tag_ columns.
                const allTagCategories = new Set();
                data.forEach(item => {
                    if (item.tags && typeof item.tags === 'object') {
                        Object.keys(item.tags).forEach(cat => {
                            allTagCategories.add(cat);
                        });
                    }
                });

                const filledData = data.map(item => {
                    const newItem = { ...item };
                    // Ensure munkaora is always a number
                    newItem.munkaora = newItem.munkaora || 0;
                    // Ensure kesz is boolean
                    newItem.kesz = newItem.kesz !== undefined ? newItem.kesz : false;
                    // Convert datum if present
                    newItem.date = item.datum ? dayjs(item.datum).toDate() : null;

                    // Flatten tags into tag_<categoryName> columns as comma-separated strings
                    if (newItem.tags && typeof newItem.tags === 'object') {
                        for (const cat of allTagCategories) {
                            const tagsForCat = newItem.tags[cat] || [];
                            newItem[`tag_${cat}`] = tagsForCat.join(', ');
                        }
                    }
                    delete newItem.tags; // we no longer need the nested tags object
                    return newItem;
                });

                setRawData(filledData);
                setFilteredData(filledData);

                // Define base columns and display names
                // Previously hardcoded columns:
                // alvallalkozo, izometria_nev, lap_szam, tipus
                // Now we do not hardcode pozicio, it will come from tags.
                const baseColumnDisplayNames = {
                    alvallalkozo: 'Alvállalkozó',
                    izometria_nev: 'Izometria',
                    lap_szam: 'Lapszám',
                    tipus: 'Típus',
                };

                // Add tag category columns to our display names
                // We'll create a display name from their category name
                const tagColumns = {};
                Array.from(allTagCategories).forEach(cat => {
                    tagColumns[`tag_${cat}`] = cat; // Use the category name as display name
                });

                const combinedDisplayNames = {
                    ...baseColumnDisplayNames,
                    ...tagColumns
                };

                setColumnDisplayNames(combinedDisplayNames);
                setGroupByOptions(Object.keys(combinedDisplayNames));
            })
            .finally(() => setLoading(false));
    }, [apiUrl, projectIDGlobal, user, setLoading, setRawData, setFilteredData, setColumnDisplayNames, setGroupByOptions]);

    useEffect(() => {
        if (groupBy.length > 0 && filteredData.length > 0) {
            setPivotData(calculatePivotData(filteredData, groupBy));
        } else if (filteredData.length > 0) {
            // Summing munkaora without any grouping
            const totalMunkaora = filteredData.reduce((sum, item) => sum + (item.munkaora || 0), 0);
            const totalKeszMunkaora = filteredData.reduce((sum, item) => {
                const itemDate = item.date ? dayjs(item.date) : null;
                if (item.kesz && itemDate && itemDate.isSameOrBefore(asOfDate.add(1, 'day'), 'day')) {
                    return sum + (item.munkaora || 0);
                }
                return sum;
            }, 0);
            const keszPercentage = totalMunkaora > 0 ? (totalKeszMunkaora / totalMunkaora) * 100 : 0;

            setPivotData([{
                totalMunkaora: Number(totalMunkaora.toFixed(2)),
                totalKeszMunkaora: Number(totalKeszMunkaora.toFixed(2)),
                keszPercentage: Number(keszPercentage.toFixed(2))
            }]);
        } else {
            setPivotData([]);
        }
    }, [groupBy, filteredData, asOfDate, setPivotData]);

    useEffect(() => {
        let filtered = rawData;
        // Apply filters
        Object.keys(filters).forEach((column) => {
            const values = filters[column];
            if (values.length > 0) {
                // If the column is a tag column, we can still just filter by value as a substring in the comma-separated string
                filtered = filtered.filter(item => {
                    const itemVal = item[column];
                    return values.includes(itemVal);
                });
            }
        });
        setFilteredData(filtered);
    }, [filters, rawData, setFilteredData]);

    const calculatePivotData = (data, groupByColumns) => {
        const groupedData = data.reduce((acc, curr) => {
            // Create a composite key based on the groupBy columns
            const key = groupByColumns.map(col => curr[col]).join('_');

            if (!acc[key]) {
                acc[key] = {
                    ...groupByColumns.reduce((obj, col) => ({ ...obj, [col]: curr[col] }), {}),
                    totalMunkaora: 0,
                    totalKeszMunkaora: 0,
                    keszPercentage: 0
                };
            }

            acc[key].totalMunkaora += curr.munkaora || 0;

            const currDatum = curr.date ? dayjs(curr.date) : null;
            if (curr.kesz && currDatum && currDatum.isSameOrBefore(asOfDate.add(1, 'day'), 'day')) {
                acc[key].totalKeszMunkaora += curr.munkaora || 0;
            }

            if (acc[key].totalMunkaora > 0) {
                acc[key].keszPercentage = (acc[key].totalKeszMunkaora / acc[key].totalMunkaora) * 100;
            }

            return acc;
        }, {});

        return Object.values(groupedData);
    };

    return (
        <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
            <div className='analytics-container' style={{ padding: '5px', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <SettingsRibbon/>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={() => setViewMode('table')}>
                        Táblázat
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => { setViewMode('chart') }} style={{ marginLeft: '10px' }}>
                        Grafikon
                    </Button>
                </div>
                <div className='data-display-container' style={{ marginTop: '20px', flexGrow: 1 }}>
                    {viewMode === 'table' ? (
                        <PivotTable />
                    ) : (
                        <ChartView/>
                    )}
                </div>
            </div>
        </LoadingWrapper>
    );
};

export default Analytics;
