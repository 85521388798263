import React from 'react';
import { Card, Badge, Button } from '@mantine/core';

const SheetCard = ({ item, onSelect, isSelected, onDelete, onRename, onClone }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{
          border: '1px solid #ddd',
          padding: '20px',
          marginBottom: '5px',
          borderRadius: '10px',
          cursor: 'pointer',
          transition: 'transform 0.2s, box-shadow 0.2s',
          width: '90%',
          willChange: 'transform',
          transform: isSelected ? 'scale(1.05)' : 'none',
          backgroundColor: isSelected ? '#eee' : 'white',
        }}
        onClick={() => onSelect(item.id)}
        onMouseEnter={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1.02)';
            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
          }
        }}
        onMouseLeave={(e) => {
          if (!isSelected) {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.boxShadow = 'none';
          }
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{item.name}</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {onRename && (
              <Button 
                size="xs" 
                variant="outline" 
                onClick={(e) => { e.stopPropagation(); onRename(item.id, item.name)}}
              >
                Átnevezés
              </Button>
            )}
            {onDelete && (
              <Button 
                size="xs" 
                color="red" 
                variant="outline" 
                onClick={(e) => { e.stopPropagation(); onDelete(item.id); }}
              >
                Törlés
              </Button>
            )}
            {onClone && (
              <Button 
                size="xs" 
                color="blue" 
                variant="outline" 
                onClick={(e) => { e.stopPropagation(); onClone(item.id); }}
              >
                Klónozás
              </Button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default SheetCard;
