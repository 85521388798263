import React, { useState, useEffect, useContext } from 'react';
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    Box,
    IconButton,
    Collapse,
    Typography,
    Grid,
    Divider
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { AnalyticsContext } from './AnalyticsContext';

// Utility function to get unique values for a column
const getUniqueValues = (data, column) => {
    const values = data.map(item => item[column]).filter(v => v !== undefined && v !== null);
    const unique = [...new Set(values)];
    return unique.map(val => ({ value: val, label: val.toString() }));
};

const SettingsRibbon = () => {
    const {
        groupBy,
        rawData,
        asOfDate,
        filters,
        columnDisplayNames,
        groupByOptions,
        setGroupBy,
        setFilteredData,
        setAsOfDate,
        setFilters
    } = useContext(AnalyticsContext);

    const [selectedColumns, setSelectedColumns] = useState(groupBy);
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        // Filtering is handled in Analytics.js on filters change
    }, [filters]);

    const handleCheckboxChange = (event) => {
        const column = event.target.name;
        const isChecked = event.target.checked;

        setSelectedColumns((prevSelectedColumns) => {
            const updatedColumns = isChecked
                ? [...prevSelectedColumns, column]
                : prevSelectedColumns.filter(col => col !== column);

            setGroupBy(updatedColumns);
            return updatedColumns;
        });
    };

    const handleFilterChange = (column, selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFilters((prevFilters) => ({
            ...prevFilters,
            [column]: values,
        }));
    };

    const handleDateChange = (date) => {
        setAsOfDate(date);
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Box
            className='settings-ribbon-container'
            sx={{
                padding: '8px',
                backgroundColor: '#f7f7f7',
                borderRadius: '8px',
                margin: '0',
                position: 'relative',
                cursor: isCollapsed ? 'pointer' : 'default'
            }}
            onClick={isCollapsed ? toggleCollapse : undefined}
        >
            <IconButton
                onClick={toggleCollapse}
                sx={{ position: 'absolute', top: '8px', right: '8px' }}
                size="small"
            >
                {isCollapsed ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
            <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
                <Box sx={{ padding: '8px' }}>
                    {/* Group By Section */}
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                        {/* Left-aligned Text */}
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 2, whiteSpace: 'nowrap' }}>
                            Lebontás:
                        </Typography>

                        {/* Centered Checkboxes */}
                        <Box className='settings-ribbon-checkboxes-container' display="flex" flexWrap="wrap" justifyContent="center" flexGrow={1} gap={6}>
                            {groupByOptions.map((option) => (
                                <FormControlLabel
                                    key={option}
                                    control={
                                        <Checkbox
                                            name={option}
                                            checked={selectedColumns.includes(option)}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={columnDisplayNames[option] || option}
                                    sx={{
                                        typography: 'body2',
                                        marginRight: 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    {/* Filters Section */}
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0 }}>
                        Szűrés:
                    </Typography>
                    <Grid container spacing={0.5}>
                        {groupByOptions.map((option) => (
                            <Grid item xs={12} sm={6} md={4} key={`filter-${option}`}>
                                <Select
                                    isMulti
                                    options={getUniqueValues(rawData, option)}
                                    onChange={(selectedOptions) => handleFilterChange(option, selectedOptions)}
                                    placeholder={`${columnDisplayNames[option] || option} szűrés`}
                                    styles={{
                                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                        control: (provided) => ({ ...provided, minHeight: '30px' }),
                                        indicatorsContainer: (provided) => ({ ...provided, height: '30px' }),
                                        input: (provided) => ({ ...provided, margin: '0px' }),
                                        multiValue: (provided) => ({
                                            ...provided,
                                            fontSize: '0.8em',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            fontSize: '0.8em',
                                        }),
                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: '4px',
                                        }),
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    // Reduce the height and font size
                                    classNamePrefix="react-select"
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    {/* Updated Date Picker Section */}
                    <Grid container alignItems="center" spacing={2}>
                        {/* Left-aligned Text */}
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Elkészültség ekkor:
                            </Typography>
                        </Grid>

                        {/* Centered Date Picker */}
                        <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Dátum"
                                    value={asOfDate}
                                    onChange={handleDateChange}
                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <input
                                                ref={inputRef}
                                                {...inputProps}
                                                style={{
                                                    height: '30px',
                                                    fontSize: '0.8em',
                                                    padding: '4px 8px',
                                                    borderRadius: '4px',
                                                    border: '1px solid #ccc',
                                                }}
                                            />
                                            {InputProps?.endAdornment}
                                        </Box>
                                    )}
                                    // Reduce the size of the date picker
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: '0.8em',
                                            padding: '4px 8px',
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1em',
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
            {isCollapsed && (
                <Box sx={{ textAlign: 'center', padding: '8px' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                        Lekérdezés beállítások
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default SettingsRibbon;
