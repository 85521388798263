import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Select } from '@mantine/core';

const AgGridSingleSelectEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || null);
  const [initialOpen, setInitialOpen] = useState(true);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const refInput = useRef(null);
  const wrapperRef = useRef(null); // Ref for the wrapper div

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    console.log('value', value);
    console.log('initialOpen', initialOpen);
    setInitialOpen(false);

    if (value !== null && !initialOpen) {
      props.stopEditing();
    }
  }, [value]);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    isPopup: () => true, // Ensures the dropdown is a popup extending beyond cell boundaries
  }));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const dropdownHeight = 200; // Estimate or calculate the dropdown height
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;

        if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
          setDropdownPosition('top');
        } else {
          setDropdownPosition('bottom');
        }
      }
    };

    // Call the function on mount
    updateDropdownPosition();

    // Optionally, add event listeners to handle window resize or scroll
    window.addEventListener('resize', updateDropdownPosition);
    window.addEventListener('scroll', updateDropdownPosition);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    };
  }, []);

  return (
    <div className="ag-cell-edit-wrapper" ref={wrapperRef}>
      <Select
        ref={refInput}
        dropdownOpened
        className="ag-cell-editor"
        style={{ width: '100%', height: '100%' }}
        data={props.options}
        value={value}
        onChange={handleChange}
        searchable
        dropdownPosition={dropdownPosition} // Dynamically set dropdown position
        clearable
        withinPortal={true}
      />
    </div>
  );
});

export default AgGridSingleSelectEditor;
