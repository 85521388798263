import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Loader, Select } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import uniqueMaterials from '../../../util/GetUniqueValues';
import Qualifications from '../Qualifications/Qualifications';

const Workforce = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subcontractorOptions, setSubcontractorOptions] = useState([]);
    const [hiddenWorkers, setHiddenWorkers] = useState([]);
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
    const [selectedWorker, setSelectedWorker] = useState(null);
    const [methods, setMethods] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [qualificationsWorkers, setQualificationsWorkers] = useState([]);
    const [triggerQualificationsRefresh, setTriggerQualificationsRefresh] = useState(false);

    const { projectIDGlobal, subcontractors, visibleSubcontractors, projectSettings } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL;

    // Define required columns
    const requiredColumns = ['name', 'tag', 'subcontractor_id'];

    // State to manage save button disabled status
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    useEffect(() => {
        console.log('Selected worker:', selectedWorker);
    }, [selectedWorker]);

    useEffect(() => {
        setLoading(true);
        if (!subcontractors || subcontractors.length === 0) {
            setLoading(false);
            return;
        }
        fetch(`${apiUrl}/api/workforce?project_id=${projectIDGlobal}&user_id=${user.userId}`)
            .then(response => response.json())
            .then(parsedData => {
                console.log('Workforce data:', parsedData);
                setHiddenWorkers(parsedData.filtered_out_workers);
                const filteredWorkers = parsedData.filtered_workers;
                console.log('Filtered workers:', filteredWorkers);
                setRowData(filteredWorkers);
                setQualificationsWorkers(filteredWorkers.map(worker => ({
                    value: worker.id,
                    label: `${worker.name} | ${worker.tag}`
                })));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching workforce:', error);
                setLoading(false);
            });
    }, [subcontractors, projectIDGlobal, user.userId, apiUrl]);

    useEffect(() => {
        let url = `${apiUrl}/api/qualifications?project_id=${projectIDGlobal}&user_id=${user.userId}`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setQualifications(data);
            })
            .catch(error => {
                console.error('Error fetching qualifications:', error);
            });
    }, [projectIDGlobal, triggerQualificationsRefresh, user.userId, apiUrl]);

    useEffect(() => {
        fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                setMethods(data.map(method => ({
                    value: method.id,
                    label: method.name
                })));
            })
            .catch(error => {
                console.error('Error fetching methods:', error);
            });
    }, [projectIDGlobal, apiUrl]);

    useEffect(() => {
        fetch(`${apiUrl}/api/materials`)
            .then(response => response.json())
            .then(data => {
                console.log('Materials:', data);
                const materialColumns = projectSettings.anyagminoseg_cols;
                console.log('MaterialColumns:', materialColumns);
                const materialData = uniqueMaterials(data, materialColumns);
                console.log('MaterialData:', materialData);
                setMaterials(materialData.list);
            })
            .catch(error => {
                console.error('Error fetching materials:', error);
            });
    }, [projectSettings, apiUrl]);

    useEffect(() => {
        const options = visibleSubcontractors.map(subcontractor => ({
            value: subcontractor.id.toString(),
            label: subcontractor.name
        }));
        setSubcontractorOptions(options);
    }, [visibleSubcontractors]);

    const findSubcontractorLabel = (subcontractorID) => {
        const subcontractor = subcontractors.find(sub => sub.id === subcontractorID);
        return subcontractor ? subcontractor.name : '';
    };

    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 100,
            editable: false,
            cellRenderer: params => (
                <button
                    onClick={() => deleteRow(params)}
                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                    title="Törlés"
                >
                    <FontAwesomeIcon icon={faTrash}/>
                </button>
            )
        },
        {
            field: 'id',
            headerName: 'ID',
            editable: false,
            hide: true,
        },
        {
            field: 'name',
            headerName: 'Név',
            editable: true,
            cellEditor: 'agTextCellEditor',
            cellStyle: params => ({
                color: params.value ? 'inherit' : 'red',
                opacity: params.value ? 1 : 0.6
            }),
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.6 }}>Név</span>;
                }
                return params.value;
            }
        },
        {
            field: 'tag',
            headerName: 'Azonosító',
            editable: true,
            cellEditor: 'agTextCellEditor',
            cellStyle: params => ({
                color: params.value ? 'inherit' : 'red',
                opacity: params.value ? 1 : 0.6
            }),
            cellRenderer: function (params) {
                if (!params.value) {
                    return <span style={{ color: 'red', opacity: 0.6 }}>Azonosító</span>;
                }
                return params.value;
            },
            // Add validation on edit stop
            onCellValueChanged: params => {
                const newTag = params.data.tag;
                const isUnique = !rowData.some(row => row.tag === newTag && row.id !== params.data.id) &&
                    !hiddenWorkers.some(worker => worker.tag === newTag);
                if (!isUnique) {
                    alert('Az azonosító már létezik!');
                    params.node.setDataValue('tag', params.oldValue);
                }
            }
        },
        {
            field: 'subcontractor_id',
            headerName: 'Alvállalkozó',
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: subcontractorOptions.map(option => option.label),
            },
            valueGetter: params => {
                const subcontractor = subcontractors.find(sub => sub.id === params.data.subcontractor_id);
                return subcontractor ? subcontractor.name : '';
            },
            valueSetter: (params) => {
                const selectedLabel = params.newValue;
                const subcontractor = subcontractorOptions.find(option => option.label === selectedLabel);
                if (subcontractor) {
                    params.data.subcontractor_id = parseInt(subcontractor.value);
                    return true;
                }
                return false;
            },
            cellRenderer: function (params) {
                const subcontractorName = findSubcontractorLabel(params.data.subcontractor_id);
                if (!subcontractorName) {
                    return <span style={{ color: 'red', opacity: 0.6 }}>Alvállalkozó</span>;
                }
                return subcontractorName;
            }
        },
        {
            headerName: 'Engedélyek',
            field: 'permissions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => {
                const workerQualifications = qualifications.filter(qualification => qualification.worker_id === params.data.id);
                const qualificationCount = workerQualifications ? workerQualifications.length : 0;

                return (
                    <Button
                        onClick={() => openPermissionModal(params.data)}
                        variant="outline"
                        size="xs"
                    >
                        {qualificationCount} Engedély
                    </Button>
                );
            }
        }
    ], [qualifications, subcontractorOptions, subcontractors, rowData, hiddenWorkers]);

    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        resizable: true,
        sortable: true,
        filter: true,
        cellStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }), []);

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const updatedRowData = [];
        gridApi.forEachNode(node => updatedRowData.push(node.data));
        setLoading(true);

        // Remove any temporary or unnecessary fields
        const requestBody = {
            data: updatedRowData.map(({ saved, ...rest }) => rest),
            user_id: user.userId
        };

        fetch(`${apiUrl}/api/workforce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
                alert('Adatok sikeresen mentve!');
            })
            .catch(error => {
                console.error('Error saving data:', error);
                setLoading(false);
                alert('Hiba történt a mentés során.');
            });
    };

    const addNewRow = () => {
        const newItem = {
            id: 0,
            name: '',
            tag: '',
            subcontractor_id: null
        };
        // Prepend the new row to the existing rowData
        setRowData(prev => [newItem, ...prev]);
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
    };

    const openPermissionModal = (workerData) => {
        setSelectedWorker(workerData);
        setIsPermissionModalOpen(true);
    };

    const closePermissionModal = () => {
        setIsPermissionModalOpen(false);
        setSelectedWorker(null);
    };

    return (
        <div className="workforce-container" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <div style={{ display: 'flex', padding: '10px', gap: '10px' }}>
                <Button
                    onClick={saveData}
                    style={{ padding: '10px' }}
                    disabled={isSaveDisabled || loading} // Disable based on validation or loading
                >
                    Mentés
                </Button>
                <Button
                    onClick={addNewRow}
                    style={{ padding: '10px' }}
                >
                    Új sor hozzáadása
                </Button>
            </div>

            {/* Scrollable Table */}
            <div style={{ flex: 1, overflow: 'auto' }}>
                <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                    <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            ref={gridRef}
                            singleClickEdit={true}
                            onGridReady={() => {
                                // Optionally, you can set the grid to auto size columns
                                gridRef.current.api.sizeColumnsToFit();
                            }}
                            onCellValueChanged={() => {
                                const hasMissingData = rowData.some(row => 
                                requiredColumns.some(col => 
                                    row[col] === null || row[col] === undefined || row[col] === ''
                                )
                                );
                                setIsSaveDisabled(hasMissingData);
                                }
                            }
                        />
                    </LoadingWrapper>
                </div>
            </div>

            {/* Permission Modal */}
            <Modal
                opened={isPermissionModalOpen}
                onClose={closePermissionModal}
                title={selectedWorker ? `${selectedWorker.id} engedélyei` : 'Engedélyek'}
                size='auto'
            >
                <div
                    style={{
                        width: '88vw',
                    }}
                >
                    {selectedWorker ? (
                        <Qualifications
                            initialRowData={qualifications}
                            initialMethods={methods}
                            initialMaterials={materials}
                            initialWorkers={qualificationsWorkers}
                            workerId={selectedWorker.id}
                            saveQualificationsCallback={() => setTriggerQualificationsRefresh(!triggerQualificationsRefresh)}
                        />
                    ) : (
                        <div>Nincs kiválasztott hegesztő</div>
                    )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button onClick={closePermissionModal}>
                        Bezárás
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default Workforce;
