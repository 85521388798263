import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faClone } from '@fortawesome/free-solid-svg-icons';
import { Button, Badge, Tooltip } from '@mantine/core';
import LoadingWrapper from '../../../Atoms/Loading';
import { Loader } from '@mantine/core';
import { GlobalContext } from '../../../Home/GlobalContext';
import AgGridSingleSelectEditor from '../../../../util/AgGridSingleSelectEditor';

const KarimaDataEntryTable = ({ selectedSheet }) => {
    const [rowData, setRowData] = useState([]);
    const [originalRowData, setOriginalRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;
    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);

    const fieldHeaderMap = {
        id: 'Mentett',
        sorszam: 'Sorszám',
        tipus: 'Típus',
        osszeepitett_vakperem: 'Összeépített vakperem',
        d: 'Külső átmérő',
        munkaora: 'Munkaóra',
    };

    const validationRules = {
        sorszam: 'number',
        tipus: 'text',
        d: 'number',
    };

    // Fetch Karima data and sheet material
    useEffect(() => {
        setLoading(true);

        const fetchKarimaData = fetch(`${apiUrl}/api/get_table?sheet_id=${selectedSheet.id}&table_name=karimaszereles`)
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data);
                console.log('Karima data:', parsedData);
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                setRowData(parsedData);
                // Save original data for comparison as deep copy
                setOriginalRowData(JSON.parse(JSON.stringify(parsedData)));
            });

        Promise.all([fetchKarimaData])
            .catch((error) => {
                console.error('Error fetching Karima data:', error);
            })
            .finally(() => setLoading(false));
    }, [selectedSheet.id, apiUrl]);

    // Validation function
    const validateRows = useCallback((rows) => {
        let isValid = true;
        let missingFields = [];

        console.log('Validating rows:', rows);

        rows.forEach((row) => {
            Object.keys(validationRules).forEach((key) => {
                const value = row[key];

                if (validationRules[key] === 'text') {
                    if (typeof value !== 'string' || value.trim() === '') {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                } else if (validationRules[key] === 'number') {
                    if (isNaN(value) || value === null) {
                        isValid = false;
                        missingFields.push(fieldHeaderMap[key]);
                    }
                }
            });
        });

        if (!isValid) {
            setTooltipContent(`Please fill out the required fields: ${missingFields.join(', ')}`);
        } else {
            setTooltipContent('');
        }

        return isValid;
    }, [validationRules, fieldHeaderMap]);

    // Function to check if a row has been modified
    const isRowModified = useCallback((currentRow) => {
        if (currentRow.id === null) return false;
        console.log(`Checking row with ID: ${currentRow.id}`);

        const originalRow = originalRowData.find(row => row.id === currentRow.id);
        if (!originalRow) return false;

        const fieldsToCompare = ['sorszam', 'tipus', 'osszeepitett_vakperem', 'd', 'munkaora'];
        for (let field of fieldsToCompare) {
            const originalValue = originalRow[field];
            const currentValue = currentRow[field];

            // Handle type differences and trimming if necessary
            if (typeof originalValue === 'number' && typeof currentValue === 'number') {
                if (originalValue !== currentValue) {
                    console.log(`Field "${field}" modified: ${originalValue} -> ${currentValue}`);
                    return true;
                }
            } else {
                if (String(originalValue).trim() !== String(currentValue).trim()) {
                    console.log(`Field "${field}" modified: "${originalValue}" -> "${currentValue}"`);
                    return true;
                }
            }
        }

        return false;
    }, [originalRowData]);

    // Handle data changes for validation
    const handleRowDataChanged = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            const gridApi = gridRef.current.api;
            const allData = [];
            gridApi.forEachNode((node) => allData.push(node.data));
            setRowData(allData); // Synchronize state with grid data
            const isValid = validateRows(allData);
            setSaveDisabled(!isValid);
        }
    }, [validateRows]);

    // Function to render badges based on row state
    const rowCellRenderer = useCallback((params) => {
        if (params.value === null) {
            return <Badge color='red'>Új</Badge>; // New row
        }
        return isRowModified(params.data) ? <Badge color='yellow'>Módosítva</Badge> : <Badge color='green'>Igen</Badge>;
    }, [isRowModified]);

    // Function to toggle checkboxes (if any)
    const toggleCheckbox = useCallback((params) => {
        const newValue = !params.value;
        params.node.setDataValue(params.colDef.field, newValue);
        handleRowDataChanged();
    }, [handleRowDataChanged]);

    // Set 'munkaora' and 'id' to null based on certain changes
    const setMunkaoraToNull = useCallback((params) => {
        const rowNode = params.api.getRowNode(params.node.id);
        rowNode.setDataValue('munkaora', null);
        handleRowDataChanged();
    }, [handleRowDataChanged]);

    // Find the lowest available 'sorszam'
    const findLowestAvailableSorszam = useCallback((api) => {
        let sorszam = 1;
        const existingSorszam = new Set();
        api.forEachNode((node) => {
            existingSorszam.add(node.data.sorszam);
        });
        while (existingSorszam.has(sorszam)) {
            sorszam++;
        }
        return sorszam;
    }, []);

    // Clone a row
    const cloneRow = useCallback((params) => {
        params.api.applyTransaction({
            add: [
                {
                    ...params.data,
                    sorszam: findLowestAvailableSorszam(params.api),
                    id: null,
                    munkaora: null,
                    osszeepitett_vakperem: false
                }
            ]
        });
        handleRowDataChanged();
    }, [findLowestAvailableSorszam, handleRowDataChanged]);

    // Delete a row
    const deleteRow = useCallback((params) => {
        params.api.applyTransaction({ remove: [params.data] });
        handleRowDataChanged();
    }, [handleRowDataChanged]);

    const gridRef = useRef(null);

    // Save data function
    const saveData = useCallback(() => {
        const gridApi = gridRef.current.api;
        const rowDataToSave = [];
        gridApi.forEachNode(node => {
            const row = {
                ...node.data,
                munkaora: node.data.munkaora !== undefined ? node.data.munkaora : null,
                d: node.data.d !== undefined ? node.data.d : null,
                osszeepitett_vakperem: node.data.osszeepitett_vakperem !== undefined ? node.data.osszeepitett_vakperem : false,
            };
            // Replace empty strings with null
            Object.keys(row).forEach(key => {
                if (row[key] === '') {
                    row[key] = null;
                }
            });
            rowDataToSave.push(row);
        });

        console.log('rowData:', rowDataToSave);
        setLoading(true);

        let requestBody = {
            data: rowDataToSave,
            sheet_id: parseInt(selectedSheet.id)
        };

        fetch(`${apiUrl}/api/calculate_karimaszereles_normhours`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const parsedData = data;
                parsedData.sort((a, b) => a.sorszam - b.sorszam);
                setRowData(parsedData);
                // Save original data for comparison as deep copy
                setOriginalRowData(JSON.parse(JSON.stringify(parsedData)));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error); // Log the error for debugging
                alert(
                    'Nem sikerült a munkaóra számolás és mentés. Ellenőrizze a beírt adatokat és próbálja újra.'
                );
                setLoading(false);
            });
    }, [apiUrl, selectedSheet.id]);

    // Add a new row
    const newRow = useCallback(() => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({
            add: [
                {
                    sorszam: findLowestAvailableSorszam(gridApi),
                    tipus: '',
                    suly: null,
                    d: null,
                    osszeepitett_vakperem: false,
                    munkaora: null,
                    id: null
                }
            ]
        });
        handleRowDataChanged();
    }, [findLowestAvailableSorszam, handleRowDataChanged]);

    // Define column definitions with custom AgGridSingleSelectEditor
    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}
                >
                    <button
                        onClick={() => cloneRow(params)}
                        style={{ border: 'none', background: 'none' }}
                    >
                        <FontAwesomeIcon icon={faClone} style={{ fontSize: '1rem' }} />
                    </button>
                    <button
                        onClick={() => deleteRow(params)}
                        style={{ border: 'none', background: 'none' }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        {
            field: 'id',
            headerName: fieldHeaderMap['id'],
            cellRenderer: rowCellRenderer
        },
        {
            field: 'sorszam',
            headerName: fieldHeaderMap['sorszam'],
            editable: true,
            cellClassRules: {
                'highlight-cell': params => !params.value
            }
        },
        {
            field: 'tipus',
            headerName: fieldHeaderMap['tipus'],
            editable: true,
            cellEditor: AgGridSingleSelectEditor,
            cellEditorParams: {
                options: [
                    { value: 'PN10-40', label: 'PN10-40' },
                    { value: 'PN64-160', label: 'PN64-160' },
                    { value: 'PN250-320', label: 'PN250-320' }
                ]
            },
            cellClassRules: {
                'highlight-cell': params => !params.value
            },
            onCellValueChanged: (params) => setMunkaoraToNull(params)
        },
        {
            field: 'osszeepitett_vakperem',
            headerName: fieldHeaderMap['osszeepitett_vakperem'],
            editable: true
        },
        {
            field: 'd',
            headerName: fieldHeaderMap['d'],
            editable: true,
            cellClassRules: {
                'highlight-cell': params => isNaN(params.value) || params.value === null
            },
            onCellValueChanged: (params) => setMunkaoraToNull(params)
        },
        {
            field: 'munkaora',
            headerName: fieldHeaderMap['munkaora'],
            editable: true
        },
    ], [rowCellRenderer, cloneRow, deleteRow, toggleCheckbox, setMunkaoraToNull, fieldHeaderMap]);

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                .highlight-cell {
                    background-color: #ffcccc;  /* Highlight color for invalid cells */
                }
                `}
            </style>

            <div style={{ display: 'flex', marginBottom: '5px' }}>
                <Tooltip label={tooltipContent} disabled={!tooltipContent}>
                    <div>
                        <Button
                            onClick={saveData}
                            style={{
                                padding: '10px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                marginRight: '5px'
                            }}
                            disabled={saveDisabled}  // Disable save button if validation fails
                        >
                            Munkaóra számolása és mentés
                        </Button>
                    </div>
                </Tooltip>

                <Button
                    onClick={newRow}
                    style={{
                        padding: '10px',
                        marginTop: '10px',
                        marginBottom: '5px'
                    }}
                >
                    Új sor hozzáadása
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        onCellValueChanged={handleRowDataChanged}
                        stopEditingWhenCellsLoseFocus={true}
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                            minWidth: 100,
                        }}
                    />
                </LoadingWrapper>
            </div>
        </>
    );
};

export default KarimaDataEntryTable;
