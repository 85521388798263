import React, { useState, useContext } from 'react';
import { Stepper, StepperProps, Group, Button } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { Loader } from '@mantine/core';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';

const Queries = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { projectIDGlobal, projectNameGlobal } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_URL;

    const onExcelDownload = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiUrl}/api/download_excel?project_id=${projectIDGlobal}&user_id=${user.userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `calctracker_${projectNameGlobal}_${new Date().toISOString().split('T')[0]}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Failed to download the Excel file');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ 
            height: '100%', 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' }}
        >
            <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                <div style={{ textAlign: 'center', margin: '10px' }}>
                    A projekt összes munkaegységének letöltése
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={onExcelDownload} disabled={loading}>
                        {loading ? 'Downloading...' : 'Excel letöltés'}
                    </Button>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </LoadingWrapper>
        </div>

    );
}

export default Queries;
