import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, TextInput, NumberInput, Select, Loader, Tooltip, MultiSelect } from '@mantine/core';
import LoadingWrapper from '../../Atoms/Loading';
import { GlobalContext } from '../../Home/GlobalContext';
import AuthContext from '../../Home/AuthContext';
import checkDisabled from '../../../util/checkDisabled';
import AgGridSingleSelectEditor from '../../../util/AgGridSingleSelectEditor';

const Wps = () => {
    // Field display name mappings
    const fieldDisplayNames = {
        name: 'Név',
        weld_material: 'Hegesztőanyag',
        positions: 'Pozíciók',
        lower_d: 'Külső átmérőtől',
        upper_d: 'Külső átmérőig',
        lower_v: 'Falvastagságtól',
        upper_v: 'Falvastagságig',
        method_id: 'Eljárás',
        description: 'Leírás'
    };

    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRowData, setCurrentRowData] = useState({});
    const [editingName, setEditingName] = useState('');
    const [methods, setMethods] = useState([]);
    const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [saveTooltip, setSaveTooltip] = useState('');

    const { projectIDGlobal, projectSettings } = useContext(GlobalContext);
    const { user } = useContext(AuthContext);

    const gridRef = useRef();

    // Refresh when methods come in
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshCells({ force: true, columns: ['method_id'] });
        }
    }, [methods]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/wps?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                // Data is already parsed JSON
                setRowData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching methods:', error);
                setLoading(false);
            });
    }, [projectIDGlobal]);


    useEffect(() => {
        fetch(`${apiUrl}/api/methods?project_id=${projectIDGlobal}`)
            .then(response => response.json())
            .then(data => {
                console.log('Methods data:', data);
                setMethods(data.map(method => ({
                    value: method.id.toString(),
                    label: method.name
                })));
            })
            .catch(error => {
                console.error('Error fetching methods:', error);
            });
    }, [projectIDGlobal]);

    const columnDefs = useMemo(() => [
        {
            headerName: 'Actions',
            maxWidth: 150,
            editable: false,
            cellRenderer: params => (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    <button onClick={() => editRow(params.data)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '1rem' }} />
                    </button>
                    <button onClick={() => deleteRow(params)} style={{ border: 'none', background: 'none' }}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '1rem' }} />
                    </button>
                </div>
            )
        },
        { field: 'name', headerName: fieldDisplayNames['name'], editable: false },
        { field: 'weld_material', headerName: fieldDisplayNames['weld_material'], editable: false },
        { field: 'positions', headerName: fieldDisplayNames['positions'], editable: false },
        { field: 'lower_d', headerName: fieldDisplayNames['lower_d'], editable: false },
        { field: 'upper_d', headerName: fieldDisplayNames['upper_d'], editable: false },
        { field: 'lower_v', headerName: fieldDisplayNames['lower_v'], editable: false },
        { field: 'upper_v', headerName: fieldDisplayNames['upper_v'], editable: false },
        {
            field: 'method_id',
            headerName: fieldDisplayNames['method_id'],
            editable: false,
            valueFormatter: (params) => {
                const method = methods.find(method => method.value === params.value?.toString());
                return method ? method.label : '';
            }
        },
        { field: 'pt', headerName: 'P/T', editable: true, cellEditor: AgGridSingleSelectEditor, cellEditorParams: { options: [{ value: 'P', label: 'P' }, { value: 'T', label: 'T' }]}},
    ], [methods]);

    const saveData = () => {
        const gridApi = gridRef.current.api;
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        rowData.forEach(row => row.project_id = projectIDGlobal);
        setLoading(true);

        fetch(`${apiUrl}/api/wps`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rowData)
        }).then(response => response.json())
            .then(() => {
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const newRow = () => {
        const newRowData = {
            id: null,
            name: '',
            weld_material: '',
            positions: [],
            lower_d: 0,
            upper_d: 0,
            lower_v: 0,
            upper_v: 0,
            method_id: null,
            description: ''
        };
        setCurrentRowData(newRowData);
        setEditingName('');
        setIsModalOpen(true);
    };

    const deleteRow = (params) => {
        const gridApi = gridRef.current.api;
        gridApi.applyTransaction({ remove: [params.data] });
        const rowData = [];
        gridApi.forEachNode(node => rowData.push(node.data));
        setRowData(rowData);
    };

    const editRow = (rowData) => {
        setCurrentRowData(rowData);
        setEditingName(rowData.name);
        setIsModalOpen(true);
    };

    const handleModalChange = (field, value) => {
        setCurrentRowData({ ...currentRowData, [field]: value });
    };

    const isNameUnique = (name, originalName) => {
        return !rowData.some(row => row.name !== originalName && row.name === name);
    };

    const saveModalData = () => {
        if (editingName === '') {
            setRowData([...rowData, currentRowData]);
        } else {
            const updatedRowData = rowData.map(row => {
                if (row.name === editingName) {
                    return currentRowData;
                }
                return row;
            });
            setRowData(updatedRowData);
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        const requiredFields = [
            'name',
            'weld_material',
            'positions',
            'method_id'
        ];

        const { isDisabled, toolTip } = checkDisabled(currentRowData, requiredFields, fieldDisplayNames);
        setSaveDisabled(isDisabled);
        setSaveTooltip(toolTip);
    }, [currentRowData]);

    const closeModal = () => {
        if (!isMultiSelectOpen) {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <style>
                {`
                .ag-header-cell-label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ag-cell-edit-wrapper {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                `}
            </style>

            <Button
                onClick={saveData}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px', marginRight: '10px' }}
            >
                Mentés
            </Button>
            <Button
                onClick={newRow}
                style={{ padding: '10px', marginTop: '10px', marginBottom: '10px' }}
            >
                Új sor hozzáadása
            </Button>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <LoadingWrapper isLoading={loading} loadingComponent={<Loader />}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        domLayout='autoHeight'
                        animateRows={true}
                        ref={gridRef}
                        editType='modal'
                        gridOptions={{
                            singleClickEdit: true
                        }}
                        defaultColDef={{
                            flex: 1,
                            filter: true,
                            cellStyle: () => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }),
                        }}
                    />
                </LoadingWrapper>
            </div>

            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                closeOnClickOutside={closeModal}
                title="Edit Row"
                >
                {/* Name Field */}
                <TextInput
                    label={fieldDisplayNames['name']}
                    value={currentRowData.name || ''}
                    onChange={(event) => handleModalChange('name', event.currentTarget.value)}
                    required
                    error={
                    !isNameUnique(currentRowData.name, editingName) && 'A név már létezik'
                    }
                />

                {/* Weld Material Field */}
                <Select
                    label={fieldDisplayNames['weld_material']}
                    data={['FM1', 'FM2', 'FM3', 'FM4', 'FM5', 'FM6']}
                    value={currentRowData.weld_material || ''}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                    setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('weld_material', value)}
                    required
                    searchable
                    withinPortal
                />

                {/* Positions Field */}
                <MultiSelect
                    label={fieldDisplayNames['positions']}
                    data={['PA', 'PB', 'PD', 'PH']}
                    value={currentRowData.positions || []}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                    setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('positions', value)}
                    required
                    searchable
                    nothingFound="Nincs találat"
                    placeholder="Válasszon pozíciót"
                />

                {/* Diameter Fields */}
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* Lower Diameter (lower_d) */}
                    <NumberInput
                    label={fieldDisplayNames['lower_d']}
                    value={currentRowData.lower_d ?? undefined}
                    onChange={(value) => handleModalChange('lower_d', value)}
                    required={false}
                    error={
                        currentRowData.lower_d !== null &&
                        currentRowData.upper_d !== null &&
                        currentRowData.lower_d > currentRowData.upper_d
                        ? 'Átmérőtől kisebb kell legyen mint Átmérőig'
                        : null
                    }
                    />

                    {/* Upper Diameter (upper_d) */}
                    <NumberInput
                    label={fieldDisplayNames['upper_d']}
                    value={currentRowData.upper_d ?? undefined}
                    onChange={(value) => handleModalChange('upper_d', value)}
                    required={false}
                    error={
                        currentRowData.lower_d !== null &&
                        currentRowData.upper_d !== null &&
                        currentRowData.upper_d < currentRowData.lower_d
                        ? 'Átmérőig nagyobb kell legyen mint Átmérőtől'
                        : null
                    }
                    />
                </div>

                {/* Thickness Fields */}
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* Lower Thickness (lower_v) */}
                    <NumberInput
                    label={fieldDisplayNames['lower_v']}
                    value={currentRowData.lower_v ?? undefined}
                    onChange={(value) => handleModalChange('lower_v', value)}
                    required={false}
                    error={
                        currentRowData.lower_v !== null &&
                        currentRowData.upper_v !== null &&
                        currentRowData.lower_v > currentRowData.upper_v
                        ? 'Falvastagságtól kisebb kell legyen mint Falvastagságig'
                        : null
                    }
                    />

                    {/* Upper Thickness (upper_v) */}
                    <NumberInput
                    label={fieldDisplayNames['upper_v']}
                    value={currentRowData.upper_v ?? undefined}
                    onChange={(value) => handleModalChange('upper_v', value)}
                    required={false}
                    error={
                        currentRowData.lower_v !== null &&
                        currentRowData.upper_v !== null &&
                        currentRowData.upper_v < currentRowData.lower_v
                        ? 'Falvastagságig nagyobb kell legyen mint Falvastagságtól'
                        : null
                    }
                    />
                </div>

                {/* Method ID Field */}
                <Select
                    label={fieldDisplayNames['method_id']}
                    data={methods}
                    value={currentRowData.method_id?.toString() || null}
                    onDropdownOpen={() => setIsMultiSelectOpen(true)}
                    onDropdownClose={() => {
                    setTimeout(() => setIsMultiSelectOpen(false), 1000);
                    }}
                    onChange={(value) => handleModalChange('method_id', value)}
                    required
                    searchable
                    nothingFound="Nincs találat"
                    placeholder="Válasszon eljárást"
                    withinPortal
                />

                {/* Description Field */}
                <TextInput
                    label={fieldDisplayNames['description']}
                    value={currentRowData.description || ''}
                    onChange={(event) => handleModalChange('description', event.currentTarget.value)}
                />

                {/* Save and Cancel Buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <Button onClick={closeModal}>Mégse</Button>
                    <Tooltip label={saveTooltip} disabled={!saveDisabled} withinPortal withArrow>
                    <div>
                        <Button onClick={saveModalData} disabled={saveDisabled}>
                        Mentés
                        </Button>
                    </div>
                    </Tooltip>
                </div>
                </Modal>


        </>
    );
};

export default Wps;
