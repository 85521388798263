import { useState, useEffect, useContext } from 'react';
import { Button, TextInput, Select } from '@mantine/core';
import { GlobalContext } from '../../../Home/GlobalContext';
import uniqueMaterials from '../../../../util/GetUniqueValues';

const SheetSummary = ({ sheetID }) => {
  const { projectSettings, subcontractors, projectIDGlobal } = useContext(GlobalContext);
  const subcontractorOptions = subcontractors.map(subcontractor => subcontractor.name);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [materials, setMaterials] = useState([]);
  const [tagsByCategory, setTagsByCategory] = useState({});
  const [selectedTags, setSelectedTags] = useState({});
  const [newSheetNumber, setNewSheetNumber] = useState('');
  const [newHeight, setNewHeight] = useState('');
  const [newMaterial, setNewMaterial] = useState('');
  const [newSubcontractor, setNewSubcontractor] = useState('');
  const [weldMaterial, setWeldMaterial] = useState('');

  useEffect(() => {
    fetch(`${apiUrl}/api/materials`)
      .then(response => response.json())
      .then(data => {
        const materialColumns = projectSettings.anyagminoseg_cols;
        const materialData = uniqueMaterials(data, materialColumns);
        console.log('materialData', materialData);
        setMaterials(materialData.list);
      });
  }, [apiUrl, projectSettings.anyagminoseg_cols]);

  useEffect(() => {
    fetch(`${apiUrl}/api/tags?project_id=${projectIDGlobal}`)
      .then((response) => response.json())
      .then((data) => {
        setTagsByCategory(data);
        fetchSheetData(data);
      });
  }, [apiUrl, projectIDGlobal]);

  const fetchSheetData = (tagsByCategory) => {
    fetch(`${apiUrl}/api/sheet?sheet_id=${sheetID}`)
      .then(response => response.json())
      .then(data => {
        setNewSheetNumber(data.sheet_number);
        setNewHeight(data.height);
        setNewMaterial(data.material);
        setWeldMaterial(data.weld_material);
        if (subcontractors.length > 0) {
          setNewSubcontractor(findSubcontractorLabel(data.subcontractor_id));
        }
        const tags = data.tags;
        const newSelectedTags = {};
        Object.keys(tags).forEach(category => {
          const tagName = tags[category][0];
          const tagObj = tagsByCategory[category]?.find(tag => tag.name === tagName);
          if (tagObj) {
            newSelectedTags[category] = tagObj;
          } else {
            newSelectedTags[category] = { id: null, name: tagName };
          }
        });
        setSelectedTags(newSelectedTags);
      });
  };

  const findSubcontractorLabel = (subcontractorID) => {
    const subcontractor = subcontractors.find(subcontractor => subcontractor.id === subcontractorID);
    return subcontractor ? subcontractor.name : '';
  };

  const handleUpdateSheet = () => {
    if (newSheetNumber && newHeight && newMaterial && newSubcontractor) {
      const selectedSubcontractor = subcontractors.find(subcontractor => subcontractor.name === newSubcontractor);

      const requestBody = {
        sheet_number: parseInt(newSheetNumber, 10),
        height: parseFloat(newHeight),
        material: newMaterial,
        subcontractor: selectedSubcontractor.id,
        tags: selectedTags,
        weld_material: weldMaterial,
      };

      fetch(`${apiUrl}/api/sheet?sheet_id=${sheetID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || 'Failed to update sheet');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Sheet updated successfully', data);
      })
      .catch(error => {
        console.error('Error updating sheet:', error);
        alert(`Error: ${error.message}`);
      });
    };
  };

  return (
    <div className='Summary'>
      <TextInput
        label="Lap száma:"
        value={newSheetNumber}
        onChange={(event) => setNewSheetNumber(event.currentTarget.value)}
      />
      <TextInput
        label="Lap magassága:"
        value={newHeight}
        onChange={(event) => setNewHeight(event.currentTarget.value)}
      />
      <Select
        label="Lap anyaga:"
        data={materials}
        value={newMaterial}
        onChange={(value) => setNewMaterial(value)}
        searchable={true}
        dropdownPosition='bottom'
        withinPortal
      />
      <Select
        label="Lap alvállakozó:"
        data={subcontractorOptions}
        value={newSubcontractor}
        onChange={(value) => setNewSubcontractor(value)}
        searchable={true}
        dropdownPosition='bottom'
        withinPortal
      />
      <Select
        label="Lap hozott anyag (FM szám):"
        data={['FM1', 'FM2', 'FM3', 'FM4', 'FM5', 'FM6']}
        value={weldMaterial}
        onChange={(value) => setWeldMaterial(value)}
        searchable={true}
        dropdownPosition='bottom'
        withinPortal
      />
      {Object.keys(tagsByCategory).map((category) => (
        <Select
          key={category}
          label={category}
          data={tagsByCategory[category].map((tag) => ({
            value: tag.name,
            label: tag.name,
          }))}
          value={selectedTags[category]?.name || ''}
          onChange={(value) => {
            const selectedTag = tagsByCategory[category].find(
              (tag) => tag.name === value
            );
            if (selectedTag) {
              setSelectedTags({ ...selectedTags, [category]: selectedTag });
            }
          }}
          searchable
          dropdownPosition="bottom"
          creatable
          withinPortal
          getCreateLabel={(query) => `+ Create "${query}"`}
          onCreate={(query) => {
            const newTag = { id: null, name: query };
            setTagsByCategory({
              ...tagsByCategory,
              [category]: [...tagsByCategory[category], newTag],
            });
            setSelectedTags({ ...selectedTags, [category]: newTag });
            return query;
          }}
        />
      ))}
      <Button className='mt-1' onClick={handleUpdateSheet}>Mentés</Button>
    </div>
  );
};

export default SheetSummary;
