import React, { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  Button,
  Box,
  Title,
  Paper,
  Group,
  Notification,
  Loader,
  Stack,
  MultiSelect,
} from '@mantine/core';
import axios from 'axios';
import { GlobalContext } from '../../Home/GlobalContext';

const ProjectSettings = () => {
  const { projectSettings, setProjectSettings, projectIDGlobal } = useContext(GlobalContext);

  // Local state for form fields
  const [settings, setSettings] = useState({
    anyagminoseg_cols: [],
    hegesztes_require_completed_by: false,
    csovezetek_require_completed_by: false,
    karimaszereles_require_completed_by: false,
    nyomasproba_require_completed_by: false,
    csotarto_require_completed_by: false,
    check_weld_positions: false,
  });

  // State for handling API call status and notifications
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '' });

  const apiUrl = process.env.REACT_APP_API_URL;

  // Initialize form fields from global projectSettings
  useEffect(() => {
    if (projectSettings) {
      setSettings({
        anyagminoseg_cols: projectSettings.anyagminoseg_cols || [],
        hegesztes_require_completed_by: projectSettings.hegesztes_require_completed_by || false,
        csovezetek_require_completed_by: projectSettings.csovezetek_require_completed_by || false,
        karimaszereles_require_completed_by: projectSettings.karimaszereles_require_completed_by || false,
        nyomasproba_require_completed_by: projectSettings.nyomasproba_require_completed_by || false,
        csotarto_require_completed_by: projectSettings.csotarto_require_completed_by || false,
        check_weld_positions: projectSettings.check_weld_positions || false,
      });
    }
  }, [projectSettings]);

  // Handlers for form field changes
  const handleCheckboxChange = (field) => (event) => {
    console.log(`Checkbox "${field}" changed:`, event); // Debugging
    if (event && event.target) {
      const isChecked = event.target.checked;
      setSettings((prev) => ({
        ...prev,
        [field]: isChecked,
      }));
    } else {
      console.error('Checkbox event or target is null');
    }
  };

  const handleMaterialColumnsChange = (value) => {
    setSettings((prev) => ({
      ...prev,
      anyagminoseg_cols: value,
    }));
  };

  // Handler for form submission
  const handleSave = async () => {
    // Validate project ID
    if (!projectIDGlobal) {
      setNotification({ message: 'Projekt ID hiányzik.', type: 'error' });
      return;
    }

    // Prepare the payload
    const updatedSettings = {
      project_id: projectIDGlobal,
      anyagminoseg_cols: settings.anyagminoseg_cols,
      hegesztes_require_completed_by: settings.hegesztes_require_completed_by,
      csovezetek_require_completed_by: settings.csovezetek_require_completed_by,
      karimaszereles_require_completed_by: settings.karimaszereles_require_completed_by,
      nyomasproba_require_completed_by: settings.nyomasproba_require_completed_by,
      csotarto_require_completed_by: settings.csotarto_require_completed_by,
      check_weld_positions: settings.check_weld_positions,
    };

    setLoading(true);
    setNotification({ message: '', type: '' });

    try {
      // Make the API call using axios
      const response = await axios.post(`${apiUrl}/api/update_project_settings`, updatedSettings, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 200) {
        // Update global state with new settings
        setProjectSettings(updatedSettings);
        setNotification({ message: 'Projekt beállítások sikeresen frissítve.', type: 'success' });
      } else {
        setNotification({ message: 'Hiba történt a beállítások frissítése során.', type: 'error' });
      }
    } catch (error) {
      console.error('API hiba:', error);
      setNotification({ message: 'Sikertelen mentés. Kérjük, próbálja újra.', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component={Paper} padding="lg" style={{ padding: '20px' }}>
      <Title order={4} mb="md">
        Projekt beállítások
      </Title>

      {/* Notification for user feedback */}
      {notification.message && (
        <Notification
          color={notification.type === 'success' ? 'green' : 'red'}
          onClose={() => setNotification({ message: '', type: '' })}
          mb="md"
        >
          {notification.message}
        </Notification>
      )}

      {/* Stack to arrange sections vertically */}
      <Stack spacing="lg">
        {/* Section: Anyagminőség Beállítások */}
        <Box>
          <Title order={5} mb="sm">
            Anyagminőség beállítások
          </Title>
          <MultiSelect
            label="Anyagminőség típusok"
            placeholder="Válassz ki típusokat"
            data={[
              { value: 'mm', label: 'MM' },
              { value: 'din', label: 'DIN' },
              { value: 'en', label: 'EN' },
              { value: 'mt', label: 'MT' },
              { value: 'astm', label: 'ASTM' },
            ]}
            value={settings.anyagminoseg_cols}
            onChange={handleMaterialColumnsChange}
            searchable
            clearable
            multiple
            fullWidth
            withinPortal
          />
        </Box>

        {/* Section: Előrehaladás Beállítások */}
        <Box>
          <Title order={5} mb="sm">
            Előrehaladás beállítások
          </Title>
          <Stack spacing="sm">
            <Checkbox
              label="Kötelezően megadandó hegesztés elkészítője"
              checked={settings.hegesztes_require_completed_by}
              onChange={handleCheckboxChange('hegesztes_require_completed_by')}
            />
            <Checkbox
              label="Kötelezően megadandó csővezeték elkészítője"
              checked={settings.csovezetek_require_completed_by}
              onChange={handleCheckboxChange('csovezetek_require_completed_by')}
            />
            <Checkbox
              label="Kötelezően megadandó kárimaszerelés elkészítője"
              checked={settings.karimaszereles_require_completed_by}
              onChange={handleCheckboxChange('karimaszereles_require_completed_by')}
            />
            <Checkbox
              label="Kötelezően megadandó nyomáspróba elkészítője"
              checked={settings.nyomasproba_require_completed_by}
              onChange={handleCheckboxChange('nyomasproba_require_completed_by')}
            />
            <Checkbox
              label="Kötelezően megadandó csotárto elkészítője"
              checked={settings.csotarto_require_completed_by}
              onChange={handleCheckboxChange('csotarto_require_completed_by')}
            />
            <Checkbox
              label="A hegesztés poziciók ellenőrzése WPS és hegesztő megaadásoknál"
              checked={settings.check_weld_positions}
              onChange={handleCheckboxChange('check_weld_positions')}
            />
          </Stack>
        </Box>
      </Stack>

      {/* Save Button */}
      <Group position="right" mt="md">
        <Button
          variant="filled"
          color="blue"
          onClick={handleSave}
          disabled={loading}
          leftIcon={loading ? <Loader size="sm" /> : null}
        >
          {loading ? 'Mentés...' : 'Mentés'}
        </Button>
      </Group>
    </Box>
  );
};

export default ProjectSettings;
 